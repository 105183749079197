@use "sass:math";

$black: #000000;
$border: #000000;
$background: #FFFFFF;
$white: #FFFFFF;
$highlight: #663886;
$backgroundHighlight: #CDC5BC;
$cta: #CDC5BC;
$sale: #FF0000;
$error: #FF0000;


$font: 'EB Garamond', Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif;
$headline: Circular, Futura, Trebuchet MS, Arial, sans-serif;
$otherlands: Lateral, sans-serif;

// Grid
$totalGridWidthPx: (45 * 12) + (12 * 11);
$gridColumns: 12;
$gut: 12px;
$gut600: 12px;
$col: math.div(100, 12);

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == max1000 {
		@media only screen and (max-width: 999px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
  background-image: repeating-linear-gradient(
    90deg,
    $gutColor,
    $gutColor $gut,
    $colColor $gut,
    $colColor #{100% / $gridColumns}
  );
  @include resp(min600) {
    background-image: repeating-linear-gradient(
      90deg,
      $gutColor,
      $gutColor $gut600,
      $colColor $gut600,
      $colColor #{100% / $gridColumns}
    );
  }
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: calc(100% - 30px); margin: 0 auto;
  @include resp(min600) { width: calc(100% - 100px); }
}

@mixin pageWidthGrid() { width: 100%; position: relative; margin: 0 auto;
  @include resp(min600) { width: calc(100% + #{$gut}); left: -$gut; }
}

@mixin logoPositionGrid() { position: absolute; left: 0;
	@include resp(minPageWidth) { left: calc(50% - #{($pageWidth / 2) + $gut600}); }
}

@mixin functionsPositionGrid() { position: absolute; right: 0;
	@include resp(min600) { right: 7px; }
	@include resp(minPageWidth) { right: calc(50% - #{($pageWidth / 2) + 13}); }
}

@mixin fz($size:body) {
	@if $size == 24 { font-size: 24px; line-height: 36px; } // H1, H2, H3
	@if $size == 18 { font-size: 18px; line-height: 26px; } // H4, H5
	@if $size == 14 { font-size: 14px; line-height: 21px; } // H4, H5
	@if $size == 12 { font-size: 12px; line-height: 21px; } // H4, H5
	@if $size == body { font-size: 14px; line-height: normal; } // Body
	@if $size == menu { font-size: 14px; line-height: normal; } // Menu

	@if $size == 16 { font-size: 12px; line-height: 1.5em; }
	@if $size == 24 { font-size: 16px; line-height: 1.5em; }
	@if $size == 36 { font-size: 24px; line-height: 1.5em; }
	@if $size == 54 { font-size: 36px; line-height: 1.5em; }
	@if $size == 72 { font-size: 36px; line-height: 1.5em; }

	@include resp(min600) {
		@if $size == 16 { font-size: 16px; line-height: 1.5em; }
		@if $size == 24 { font-size: 24px; line-height: 1.5em; }
		@if $size == 36 { font-size: 36px; line-height: 1.5em; }
		@if $size == 54 { font-size: 54px; line-height: 1.5em; }
		@if $size == 72 { font-size: 72px; line-height: 1.5em; }
	}
}

@mixin headline() { font-family: $headline; font-weight: 700; letter-spacing: 0em; margin: 0; }
@mixin body() { font-family: $font; @include fz(body); }
@mixin menu() { font-family: $headline; @include fz(menu); }
@mixin subtitle() { font-family: $font; @include fz(body); font-weight: 700; letter-spacing: 0em; text-transform: uppercase; }

.pageEnd { position: relative; z-index: 201; }

.manifest { width: 700px; max-width: 100%; padding: 20px; box-sizing: border-box; margin: 0 auto; text-align: center; font-size: 18px; }

.magazineSnippets { padding: 20px; box-sizing: border-box;
	&_title { text-align: center; display: block; font-family: $headline; margin-bottom: 40px; 
		span { border-bottom: 1px solid $black; }
	}
	&_column { text-align: center; margin-bottom: 20px;
		&_img { width: 100%; height: auto; display: block; }
		&_text {
			> a { border-bottom: none; }
			> a + a, > a .magazineSnippets_column_text_link { border-bottom: 1px solid $black; }
			&_preHeader { font-style: italic; margin-top: 10px; display: inline-block; }
			&_headerText { font-size: 18px; font-family: $headline; display: block; margin: 10px 0; }
			&_snippet { font-size: 16px; }
			&_link { font-size: 14px; margin-top: 10px; display: inline-block; font-family: $headline; }
		}
	}
	@include resp(min600) { padding: 20px 20px 40px;
		&_column { width: 31%; display: inline-block; vertical-align: top; margin-left: 3.5%; margin-bottom: 0;
			&:nth-child(3n-2) { margin-left: 0; }
		}
	}
	@include resp(min900) { padding: 20px 20px 60px;
		&_column_text {
			&_snippet { width: 70%; margin: 0 15%; }
		}
	}
}

.footer { position: relative; z-index: 1; background-color: $backgroundHighlight; padding: 20px; box-sizing: border-box; font-family: $headline; transform: translate3d(0, 0, 0); transition: transform .3s ease-in-out;
	&_top { width: 100%; text-align: center;
		&_newsletter {
			&_header { margin-bottom: 10px; }
			button { width: 100px; }
		}
		&_social { margin-top: 40px;
			&_icons { margin: 5px 0;
				a { display: inline-block; vertical-align: top; padding: 5px; border: none; }
			}
		}
	}
	&_menu { margin-top: 30px; text-align: center;
		&_column { width: 100%; display: inline-block; vertical-align: top; padding: 0; list-style: none; margin: 0 0 20px;
			&_headline { text-transform: uppercase; padding: 10px 0; margin: 0; display: block; border-bottom: none; }
			&_link { display: none; border-bottom: none; padding: 6px 0; }
			&__expanded &_link { display: block; }
		}
	}
	&_service { text-align: center; margin: 20px 0 10px;
		&_icon { height: 38px; width: auto; margin: 0 10px; display: inline-block; }
	}
	&_organisation { text-align: center; display: block; margin-bottom: 20px; }
	@include resp(min600) {
		&_top { text-align: left;
			&_newsletter { width: 50%; display: inline-block; vertical-align: top; padding-right: 20px; box-sizing: border-box; }
			&_social { width: 50%; display: inline-block; vertical-align: top; margin-top: 0; 
				&_icons { margin: 5px 10px 10px -5px; }
			}
		}
		&_menu { text-align: left;
			&_column { width: 25%; margin: 0;
				&_headline { margin-bottom: 10px; padding: 0; }
				&_link { display: block; padding: 0; }
			}
		}
		&_service { margin: 50px 0 10px;
			&_icon { height: 52px; }
		}
	}
	@include resp(max600) {
		&_menu_column_headline h5 { padding-right: 15px; @include chevronDown('before'); 
			&:before { margin-right: 10px; }
		}
		&_menu_column__expanded h5:before { transform: rotateZ(225deg); margin-top: 7px; }
	}
}

@media print {
	.pageEnd { display: none; }
}