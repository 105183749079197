@use "sass:math";

$black: #000000;
$border: #000000;
$background: #FFFFFF;
$white: #FFFFFF;
$highlight: #663886;
$backgroundHighlight: #CDC5BC;
$cta: #CDC5BC;
$sale: #FF0000;
$error: #FF0000;


$font: 'EB Garamond', Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif;
$headline: Circular, Futura, Trebuchet MS, Arial, sans-serif;
$otherlands: Lateral, sans-serif;

// Grid
$totalGridWidthPx: (45 * 12) + (12 * 11);
$gridColumns: 12;
$gut: 12px;
$gut600: 12px;
$col: math.div(100, 12);

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == max1000 {
		@media only screen and (max-width: 999px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
  background-image: repeating-linear-gradient(
    90deg,
    $gutColor,
    $gutColor $gut,
    $colColor $gut,
    $colColor #{100% / $gridColumns}
  );
  @include resp(min600) {
    background-image: repeating-linear-gradient(
      90deg,
      $gutColor,
      $gutColor $gut600,
      $colColor $gut600,
      $colColor #{100% / $gridColumns}
    );
  }
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: calc(100% - 30px); margin: 0 auto;
  @include resp(min600) { width: calc(100% - 100px); }
}

@mixin pageWidthGrid() { width: 100%; position: relative; margin: 0 auto;
  @include resp(min600) { width: calc(100% + #{$gut}); left: -$gut; }
}

@mixin logoPositionGrid() { position: absolute; left: 0;
	@include resp(minPageWidth) { left: calc(50% - #{($pageWidth / 2) + $gut600}); }
}

@mixin functionsPositionGrid() { position: absolute; right: 0;
	@include resp(min600) { right: 7px; }
	@include resp(minPageWidth) { right: calc(50% - #{($pageWidth / 2) + 13}); }
}

@mixin fz($size:body) {
	@if $size == 24 { font-size: 24px; line-height: 36px; } // H1, H2, H3
	@if $size == 18 { font-size: 18px; line-height: 26px; } // H4, H5
	@if $size == 14 { font-size: 14px; line-height: 21px; } // H4, H5
	@if $size == 12 { font-size: 12px; line-height: 21px; } // H4, H5
	@if $size == body { font-size: 14px; line-height: normal; } // Body
	@if $size == menu { font-size: 14px; line-height: normal; } // Menu

	@if $size == 16 { font-size: 12px; line-height: 1.5em; }
	@if $size == 24 { font-size: 16px; line-height: 1.5em; }
	@if $size == 36 { font-size: 24px; line-height: 1.5em; }
	@if $size == 54 { font-size: 36px; line-height: 1.5em; }
	@if $size == 72 { font-size: 36px; line-height: 1.5em; }

	@include resp(min600) {
		@if $size == 16 { font-size: 16px; line-height: 1.5em; }
		@if $size == 24 { font-size: 24px; line-height: 1.5em; }
		@if $size == 36 { font-size: 36px; line-height: 1.5em; }
		@if $size == 54 { font-size: 54px; line-height: 1.5em; }
		@if $size == 72 { font-size: 72px; line-height: 1.5em; }
	}
}

@mixin headline() { font-family: $headline; font-weight: 700; letter-spacing: 0em; margin: 0; }
@mixin body() { font-family: $font; @include fz(body); }
@mixin menu() { font-family: $headline; @include fz(menu); }
@mixin subtitle() { font-family: $font; @include fz(body); font-weight: 700; letter-spacing: 0em; text-transform: uppercase; }

.header { position: relative; z-index: 300;
	&_clickHoverRemove { position: fixed; z-index: 100000; top: 0; left: 0; width: 100%; height: 100vh; }
	&_fixedContainer { height: 48px; }
	&_links { position: relative; padding: 15px 0 0;
		&_byline { position: absolute; left: 20px; font-family: $headline; display: none; 
			@include resp(min1000) { display: block; }
		}
		&_stickyLogo { display: none; border: none; }
		@include resp(min750) { padding: 15px 0; }
	}

	.functions { position: absolute; right: 10px; top: 2px; z-index: 101; font-family: $headline;
		> a { border-bottom: none; vertical-align: middle; display: inline-block; padding: 10px; }
		&_selection { border-bottom: none; }
		&_location { vertical-align: middle; display: inline-block; padding: 8px 10px 10px; position: relative;			
			&_title { @include chevronDown(before); border: none;
				&:before { margin-right: 10px; }
				span { border-bottom: 1px solid $black; }
			}
			&_dropdown { position: absolute; width: 240px; left: -50%; top: 41px; padding: 10px; display: none; background-color: $white; box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15);
				&_language { padding: 10px 10px 20px; text-align: center;
					&_link { display: inline-block;
						a { border: none; padding: 10px; opacity: 0.3; }
						&__selected a { opacity: 1; cursor: default; }
						span { display: inline-block; vertical-align: middle; width: 1px; height: 20px; background-color: $black; }
						&:last-child span { display: none; }
					}
				}
				&_shipTo { font-family: $font; font-size: 14px; padding-top: 5px; display: block; }
			}
			&__open {
				.functions_location {
					&_title:before { transform: rotateZ(225deg); margin-top: 8px; }
					&_dropdown { display: block; }
				}
			}
		}
	}
}

.searchScreen { position: fixed; top: 0; left: 0; height: 100%; width: 100%; background-color: $white; z-index: 200; }
.search { position: relative; z-index: 200; width: 100%;
	&__active { min-height: 50vh;
		~ main { display: none; }
	}
}

.cart { position: absolute; right: 0; overflow: hidden; z-index: -1; margin-top: -2px;
	&_inner { background-color: $white; width: 320px; position: absolute; right: -320px; z-index: 100; transform: translate3d(0, 0, 0); transition: transform 0.3s ease-in-out; box-sizing: border-box; margin-top: 3px; box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.15); }
}

:global(.headerIsFixed) {
	.header_links { position: fixed; top: 0; left: 0; width: 100%; background-color: $white; }
	.cart { position: fixed; top: 33px; }
}

:global(.headerIsMinimized) {
	.header_links_stickyLogo { display: none;
		@include resp(min1000) { display: block;
			img { position: absolute; top: 13px; left: 20px; width: 150px; z-index: 1000; }
		}
	}
	.header_links_byline { display: none; }
}

:global(.is-showingCart__entering) {
	~ .cart { z-index: 1000; overflow: visible;
		.cart_inner { transform: translate3d(-320px, 0, 0); }
	}
}

:global(.is-showingCart__exiting) {
	~ .cart { z-index: 1000; overflow: visible; }
}

@media print {
	.header_logo img { width: 70%; margin: 0 auto; }
	.header .functions { display: none; }
}
