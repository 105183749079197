@use "sass:math";

$black: #000000;
$border: #000000;
$background: #FFFFFF;
$white: #FFFFFF;
$highlight: #663886;
$backgroundHighlight: #CDC5BC;
$cta: #CDC5BC;
$sale: #FF0000;
$error: #FF0000;


$font: 'EB Garamond', Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif;
$headline: Circular, Futura, Trebuchet MS, Arial, sans-serif;
$otherlands: Lateral, sans-serif;

// Grid
$totalGridWidthPx: (45 * 12) + (12 * 11);
$gridColumns: 12;
$gut: 12px;
$gut600: 12px;
$col: math.div(100, 12);

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == max1000 {
		@media only screen and (max-width: 999px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
  background-image: repeating-linear-gradient(
    90deg,
    $gutColor,
    $gutColor $gut,
    $colColor $gut,
    $colColor #{100% / $gridColumns}
  );
  @include resp(min600) {
    background-image: repeating-linear-gradient(
      90deg,
      $gutColor,
      $gutColor $gut600,
      $colColor $gut600,
      $colColor #{100% / $gridColumns}
    );
  }
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: calc(100% - 30px); margin: 0 auto;
  @include resp(min600) { width: calc(100% - 100px); }
}

@mixin pageWidthGrid() { width: 100%; position: relative; margin: 0 auto;
  @include resp(min600) { width: calc(100% + #{$gut}); left: -$gut; }
}

@mixin logoPositionGrid() { position: absolute; left: 0;
	@include resp(minPageWidth) { left: calc(50% - #{($pageWidth / 2) + $gut600}); }
}

@mixin functionsPositionGrid() { position: absolute; right: 0;
	@include resp(min600) { right: 7px; }
	@include resp(minPageWidth) { right: calc(50% - #{($pageWidth / 2) + 13}); }
}

@mixin fz($size:body) {
	@if $size == 24 { font-size: 24px; line-height: 36px; } // H1, H2, H3
	@if $size == 18 { font-size: 18px; line-height: 26px; } // H4, H5
	@if $size == 14 { font-size: 14px; line-height: 21px; } // H4, H5
	@if $size == 12 { font-size: 12px; line-height: 21px; } // H4, H5
	@if $size == body { font-size: 14px; line-height: normal; } // Body
	@if $size == menu { font-size: 14px; line-height: normal; } // Menu

	@if $size == 16 { font-size: 12px; line-height: 1.5em; }
	@if $size == 24 { font-size: 16px; line-height: 1.5em; }
	@if $size == 36 { font-size: 24px; line-height: 1.5em; }
	@if $size == 54 { font-size: 36px; line-height: 1.5em; }
	@if $size == 72 { font-size: 36px; line-height: 1.5em; }

	@include resp(min600) {
		@if $size == 16 { font-size: 16px; line-height: 1.5em; }
		@if $size == 24 { font-size: 24px; line-height: 1.5em; }
		@if $size == 36 { font-size: 36px; line-height: 1.5em; }
		@if $size == 54 { font-size: 54px; line-height: 1.5em; }
		@if $size == 72 { font-size: 72px; line-height: 1.5em; }
	}
}

@mixin headline() { font-family: $headline; font-weight: 700; letter-spacing: 0em; margin: 0; }
@mixin body() { font-family: $font; @include fz(body); }
@mixin menu() { font-family: $headline; @include fz(menu); }
@mixin subtitle() { font-family: $font; @include fz(body); font-weight: 700; letter-spacing: 0em; text-transform: uppercase; }

.productItem { width: calc(50% - 6px); margin-left: 12px; display: inline-block; vertical-align: top; position: relative;
	&_favourites { position: absolute; top: 0; right: 0; z-index: 10; display: none; padding: 10px; border: none;
		&_text { font-family: $headline; display: none; vertical-align: top; margin-right: 10px; }
	}
	&_link { border: none; }
	&_images { position: relative; width: 100%;
		img { width: 100%; height: auto; display: block; }
		img + img { position: absolute; top: 0; left: 0; opacity: 0; transition: opacity 0.15s; }
	}
	&_text { margin: 9px 0 25px; font-family: $headline; text-align: left;
		&_brand { display: block; }
		&_name {}
		&_price {
			&_sale { color: $sale; }
		}
	}
	&__soldOut {}
	&__sale {}
	&__new {}
	:global(.no-touchevents) &:hover &_images img:last-child { opacity: 1; }
	:global(.no-touchevents) &:hover &_favourites { display: block; 
		&:hover .productItem_favourites_text { display: inline-block; }
	}
	&:nth-child(2n-1) { margin-left: 0; }
	@include resp(min600) { width: calc(33.33% - 8px);
		&:nth-child(n) { margin-left: 12px; }
		&:nth-child(3n-2) { margin-left: 0; }
	}
	@include resp(min900) { width: calc(25% - 9px);
		&:nth-child(n) { margin-left: 12px; }
		&:nth-child(4n-3) { margin-left: 0; }
	}
	@include resp(max600) { 
		&:global(.u_productPicker) &:nth-child(2n+1):last-child:not(:first-child) { display: none; } 
	}
	&__list { width: 100%; 
		.productItem {
			&_favourites { display: block; }
			@include resp(min600) {
				&_images { display: inline-block; vertical-align: bottom; width: 30%; }
				&_text { display: inline-block; vertical-align: bottom; width: 70%; box-sizing: border-box; padding-left: 20px; margin: 9px 0 56px; 
					&_price { float: right; }
				}
				&_purchase { width: 70%; box-sizing: border-box; padding-left: 20px; position: absolute; bottom: 0; right: 0; }
			}
		}
	}
	&__depict { width: 100%; }
}

:global(.productsPerRow_1) {
	.productItem { width: 100%; 
		&:nth-child(n) { margin-left: 0; }
	}
}

:global(.productsPerRow_2) {
	.productItem { width: calc(50% - 6px); 
		&:nth-child(n) { margin-left: 12px; }
		&:nth-child(2n-1) { margin-left: 0; }
	}
}

:global(.productsPerRow_3) {
	.productItem { 
		@include resp(min600) { width: calc(33.33% - 8px); 
			&:nth-child(n) { margin-left: 12px; }
			&:nth-child(3n-2) { margin-left: 0; }
		}
	}
}

:global(.productsPerRow_5) {
	.productItem { 
		@include resp(min900) { width: calc(20% - 9.6px); 
			&:nth-child(n) { margin-left: 12px; }
			&:nth-child(5n-4) { margin-left: 0; }
		}
	}
}

:global(.productsPerRow_6) {
	.productItem { 
		@include resp(min900) { width: calc(16.66% - 10px); 
			&:nth-child(n) { margin-left: 12px; }
			&:nth-child(6n-5) { margin-left: 0; }
		}
	}
}