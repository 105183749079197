@use "sass:math";

$black: #000000;
$border: #000000;
$background: #FFFFFF;
$white: #FFFFFF;
$highlight: #663886;
$backgroundHighlight: #CDC5BC;
$cta: #CDC5BC;
$sale: #FF0000;
$error: #FF0000;


$font: 'EB Garamond', Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif;
$headline: Circular, Futura, Trebuchet MS, Arial, sans-serif;
$otherlands: Lateral, sans-serif;

// Grid
$totalGridWidthPx: (45 * 12) + (12 * 11);
$gridColumns: 12;
$gut: 12px;
$gut600: 12px;
$col: math.div(100, 12);

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == max1000 {
		@media only screen and (max-width: 999px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
  background-image: repeating-linear-gradient(
    90deg,
    $gutColor,
    $gutColor $gut,
    $colColor $gut,
    $colColor #{100% / $gridColumns}
  );
  @include resp(min600) {
    background-image: repeating-linear-gradient(
      90deg,
      $gutColor,
      $gutColor $gut600,
      $colColor $gut600,
      $colColor #{100% / $gridColumns}
    );
  }
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: calc(100% - 30px); margin: 0 auto;
  @include resp(min600) { width: calc(100% - 100px); }
}

@mixin pageWidthGrid() { width: 100%; position: relative; margin: 0 auto;
  @include resp(min600) { width: calc(100% + #{$gut}); left: -$gut; }
}

@mixin logoPositionGrid() { position: absolute; left: 0;
	@include resp(minPageWidth) { left: calc(50% - #{($pageWidth / 2) + $gut600}); }
}

@mixin functionsPositionGrid() { position: absolute; right: 0;
	@include resp(min600) { right: 7px; }
	@include resp(minPageWidth) { right: calc(50% - #{($pageWidth / 2) + 13}); }
}

@mixin fz($size:body) {
	@if $size == 24 { font-size: 24px; line-height: 36px; } // H1, H2, H3
	@if $size == 18 { font-size: 18px; line-height: 26px; } // H4, H5
	@if $size == 14 { font-size: 14px; line-height: 21px; } // H4, H5
	@if $size == 12 { font-size: 12px; line-height: 21px; } // H4, H5
	@if $size == body { font-size: 14px; line-height: normal; } // Body
	@if $size == menu { font-size: 14px; line-height: normal; } // Menu

	@if $size == 16 { font-size: 12px; line-height: 1.5em; }
	@if $size == 24 { font-size: 16px; line-height: 1.5em; }
	@if $size == 36 { font-size: 24px; line-height: 1.5em; }
	@if $size == 54 { font-size: 36px; line-height: 1.5em; }
	@if $size == 72 { font-size: 36px; line-height: 1.5em; }

	@include resp(min600) {
		@if $size == 16 { font-size: 16px; line-height: 1.5em; }
		@if $size == 24 { font-size: 24px; line-height: 1.5em; }
		@if $size == 36 { font-size: 36px; line-height: 1.5em; }
		@if $size == 54 { font-size: 54px; line-height: 1.5em; }
		@if $size == 72 { font-size: 72px; line-height: 1.5em; }
	}
}

@mixin headline() { font-family: $headline; font-weight: 700; letter-spacing: 0em; margin: 0; }
@mixin body() { font-family: $font; @include fz(body); }
@mixin menu() { font-family: $headline; @include fz(menu); }
@mixin subtitle() { font-family: $font; @include fz(body); font-weight: 700; letter-spacing: 0em; text-transform: uppercase; }

.notification { text-align: center; position: fixed; z-index: 1200; left: 0; top: 0; right: 0; bottom: 0;
	&_screen { position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: rgba($black, 0.25); }
	&_inner { background-color: $white; position: absolute; left: 0; bottom: 0; width: 100%; max-height: 70vh; padding: 10px 10px 0; box-sizing: border-box; overflow: hidden; overflow-y: auto; -webkit-overflow-scrolling: touch;
		&_container { font-family: $headline; text-align: left; padding: 10px 10px 4px; opacity: 1; display: flex; flex-flow: column;
			&_text { display: inline-block; margin-bottom: 14px; }
			&_button { margin: 0 0 10px 0; 
				button { margin-bottom: 0; }
				&:last-child { margin-bottom: 15px; }
			}
			&_submit { background-color: $white; margin: 10px 0 0; padding: 10px 0 15px; display: flex; flex-flow: column nowrap; position: sticky; bottom: 0; box-sizing: border-box; order: 2;
				&::before { content: ""; height: 30px; width: 100%; position: absolute; left: 0; top: -30px; background-image: linear-gradient(to bottom, rgba($white, 0), rgba($white, 1)); }
			}
			&__exiting, &__exited { opacity: 1; transform: translateY(calc(100% + 20px)); }
		}
	}
	@include resp(min600) {
		&_inner {
			&_container { width: calc(75% - 22px); margin: 0 auto;
				&_submit { margin-bottom: 0; 
					button { margin-bottom: 0;
						&:first-child { margin-bottom: 10px; }
					}
				}
			}
		}
	}
	@include resp(min900) {
		&_inner { padding: 0; max-height: calc(100dvh - 84px); height: auto; bottom: 50%; left: 50%; width: 550px; max-width: 100%; transform: translate(-50%, 50%);
			&_container { width: 100%; box-sizing: border-box; padding: 11px 13px 0;
				&_text { margin-right: 10px; }
				&_submit { padding-top: 14px; }
			}
		}
	}
}
