@use "sass:math";

$black: #000000;
$border: #000000;
$background: #FFFFFF;
$white: #FFFFFF;
$highlight: #663886;
$backgroundHighlight: #CDC5BC;
$cta: #CDC5BC;
$sale: #FF0000;
$error: #FF0000;


$font: 'EB Garamond', Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif;
$headline: Circular, Futura, Trebuchet MS, Arial, sans-serif;
$otherlands: Lateral, sans-serif;

// Grid
$totalGridWidthPx: (45 * 12) + (12 * 11);
$gridColumns: 12;
$gut: 12px;
$gut600: 12px;
$col: math.div(100, 12);

$pageWidth: $totalGridWidthPx * 1px;

@mixin resp($media) {
	@if $media == max600 {
		@media only screen and (max-width: 599px) { @content; }
	} @else if $media == min600 {
		@media only screen and (min-width: 600px) { @content; }
	} @else if $media == max750 {
		@media only screen and (max-width: 749px) { @content; }
	} @else if $media == min750 {
		@media only screen and (min-width: 750px) { @content; }
	} @else if $media == max900 {
		@media only screen and (max-width: 899px) { @content; }
	} @else if $media == min900 {
		@media only screen and (min-width: 900px) { @content; }
	} @else if $media == max1000 {
		@media only screen and (max-width: 999px) { @content; }
	} @else if $media == min1000 {
		@media only screen and (min-width: 1000px) { @content; }
	} @else if $media == max1200 {
		@media only screen and (max-width: 1159px) { @content; }
	} @else if $media == min1200 {
		@media only screen and (min-width: 1200px) { @content; }
	}
}

@mixin chevronDown($pos: after) { 
	@if $pos == before {
		&:before { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	} @else {
		&:after { content: ""; width: 8px; height: 8px; border-bottom: 1.5px solid; border-right: 1.5px solid; transform: rotateZ(45deg); margin: 3px 0 0 10px; display: inline-block; vertical-align: top; }
	}
}

@mixin grid($total, $col, $gut, $colColor, $gutColor) {
  background-image: repeating-linear-gradient(
    90deg,
    $gutColor,
    $gutColor $gut,
    $colColor $gut,
    $colColor #{100% / $gridColumns}
  );
  @include resp(min600) {
    background-image: repeating-linear-gradient(
      90deg,
      $gutColor,
      $gutColor $gut600,
      $colColor $gut600,
      $colColor #{100% / $gridColumns}
    );
  }
}

@function strip-unit($num) {
	@return $num / ($num * 0 + 1);
}

@function w($num, $resp: 0, $multiple: 1) {
	$percent: math.div(100, 12) * $num * 1%;
	$less: 12 * $multiple;

	@return calc(#{$percent} - #{$less}px);
}

@mixin pageWidth() { width: calc(100% - 30px); margin: 0 auto;
  @include resp(min600) { width: calc(100% - 100px); }
}

@mixin pageWidthGrid() { width: 100%; position: relative; margin: 0 auto;
  @include resp(min600) { width: calc(100% + #{$gut}); left: -$gut; }
}

@mixin logoPositionGrid() { position: absolute; left: 0;
	@include resp(minPageWidth) { left: calc(50% - #{($pageWidth / 2) + $gut600}); }
}

@mixin functionsPositionGrid() { position: absolute; right: 0;
	@include resp(min600) { right: 7px; }
	@include resp(minPageWidth) { right: calc(50% - #{($pageWidth / 2) + 13}); }
}

@mixin fz($size:body) {
	@if $size == 24 { font-size: 24px; line-height: 36px; } // H1, H2, H3
	@if $size == 18 { font-size: 18px; line-height: 26px; } // H4, H5
	@if $size == 14 { font-size: 14px; line-height: 21px; } // H4, H5
	@if $size == 12 { font-size: 12px; line-height: 21px; } // H4, H5
	@if $size == body { font-size: 14px; line-height: normal; } // Body
	@if $size == menu { font-size: 14px; line-height: normal; } // Menu

	@if $size == 16 { font-size: 12px; line-height: 1.5em; }
	@if $size == 24 { font-size: 16px; line-height: 1.5em; }
	@if $size == 36 { font-size: 24px; line-height: 1.5em; }
	@if $size == 54 { font-size: 36px; line-height: 1.5em; }
	@if $size == 72 { font-size: 36px; line-height: 1.5em; }

	@include resp(min600) {
		@if $size == 16 { font-size: 16px; line-height: 1.5em; }
		@if $size == 24 { font-size: 24px; line-height: 1.5em; }
		@if $size == 36 { font-size: 36px; line-height: 1.5em; }
		@if $size == 54 { font-size: 54px; line-height: 1.5em; }
		@if $size == 72 { font-size: 72px; line-height: 1.5em; }
	}
}

@mixin headline() { font-family: $headline; font-weight: 700; letter-spacing: 0em; margin: 0; }
@mixin body() { font-family: $font; @include fz(body); }
@mixin menu() { font-family: $headline; @include fz(menu); }
@mixin subtitle() { font-family: $font; @include fz(body); font-weight: 700; letter-spacing: 0em; text-transform: uppercase; }

.navigation { position: relative; font-family: $headline;
	ul { list-style: none; }
	&_miniLogo { display: none; padding: 0 0 0 20px; border: none; position: relative; top: -8px; margin-right: -8px; 
		@include resp(min750) { top: -4px; }
	}
	&_mobileMenu { position: relative; top: -6px; width: 30px; height: 30px; display: inline-block; padding: 0 20px 0 10px; margin-left: 10px; border-bottom: none;
		span { width: 21px; height: 1.5px; position: absolute; top: 50%; left: 10px; display: block; background-color: $black; transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
			&:first-child { transform: translateY(-9px); }
			&:last-child { transform: translateY(9px); }
		}
		&__open {
			span { opacity: 0;
				&:first-child { opacity: 1; transform: translateY(0) rotateZ(-45deg); }
				&:last-child { opacity: 1; transform: translateY(0) rotateZ(45deg); }
			}
			+ .level1 { display: block; min-width: 65vw; }
		}
		@include resp(min750) { display: none; }
	}
	@include resp(max1000) { 
		:global(.headerIsMinimized) &_miniLogo { display: inline-block; }
	}
}

.level1 { position: absolute; display: none; text-align: left; margin: 0; padding: 0 10px; box-sizing: border-box; background-color: $white;
	a { border-bottom: none; display: block; }
	&_column { text-align: left;
		&_link { padding: 10px 10px 10px 40px; position: relative; z-index: 101;
			&_expandIndicator { display: none; }
			span { border-bottom: 1px solid transparent; }
			&__sale { color: $sale; }
		}
		&__expanded {
			> a span { border-bottom-color: $black; }
			.level2 { display: block; }
			.level1 {
				&_columnInner { display: block; }
				&_column_link_expandIndicator:before { transform: rotateZ(-45deg); margin-top: 5px; margin-left: 8px; }
			}
		}
	}
	@include resp(min750) { display: inline-block; vertical-align: top; position: static; background-color: transparent;
		&_column { display: inline-block; vertical-align: top; 
			&_link { padding: 10px 10px 0; margin-top: -10px; }
			:global(.no-touchevents) &:hover {
				> a span { border-bottom-color: $black; }
				.level2 { display: flex; }
				.level1_columnInner { display: block; }
			}
			:global(.no-touchevents) &:hover &_link__sale > span { border-bottom-color: $sale; }
		}
		&_columnInner { padding-top: 18px; width: 100%; position: absolute; left: 0; top: 15px; display: none; }
	}
	@include resp(max750) { overflow: auto; -webkit-overflow-scrolling: touch;
		&_column:last-child { margin-bottom: 20px; }
		&_column_link_expandIndicator { display: block; position: absolute; @include chevronDown('before'); 
			&:before { position: absolute; left: -36px; }
		}
	}
	@include resp(min1000) { text-align: center; display: block; }
}

.level2 { padding: 0 20px 20px 40px; flex-flow: row no-wrap; justify-content: space-between; box-sizing: border-box; display: none; z-index: 100; width: 100%; text-align: left;
	&_block { box-sizing: border-box;
		&_title { text-transform: uppercase; }
		&_item { text-align: left;
			&_link { padding: 5px 0; 
				span { border-bottom: 1px solid transparent; }
				:global(.no-touchevents) &:hover span { border-bottom-color: $black; }
				.level2_block_item_link_underlined { border-bottom-color: $black; }
				&__sale { color: $sale; 
					:global(.no-touchevents) &:hover span { border-bottom-color: $sale; }
				}
			}
		}
		&__noSubCategories &_item_link {
			span { border-bottom: 1px solid transparent; }
			:global(.no-touchevents) &:hover span { border-bottom-color: $black; }
		}
		&__campaignImage {  }
		@include resp(max600) { 
			&:not(:first-child) &_item_link {
				span { margin-left: 10px; }
				.level2_block_item_link_underlined { margin-left: 0; }
			}
		}
	}
	&__minimal { justify-content: center; }
	@include resp(min750) { padding: 0 20px 20px; position: absolute; left: 0; top: 15px; background-color: $white; overflow: hidden; box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
		&_block { display: flex; flex-flow: column wrap; align-items: left; width: 20%; max-width: 240px;
			&_item { display: inline-block; vertical-align: top; 
				&_link { padding: 2px 0; }
			}
			&__doubleColumn { width: 40%; max-width: 480px; }
			&__campaign { border-left: 1px solid $black; padding-left: 30px; 
				&:empty { display: none; }
			}
		}
	}
	@include resp(max750) {
		&_block { height: auto !important; }
	}
}

@media print {
	.navigation { display: none; }
}
